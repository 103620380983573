<template>
  <div class="popup_overlay relative px-4">
      <div v-if="viewDetail !== null" style="width: 840px; max-height:90%; overflow: auto;" class="custom_dialog rounded-xl h-min">
      <div>
        <div class="cus_card">
          <div class=" rounded-tl rounded-tr px-5 py-2 bg-primary text-white heading-4 font-semibold flex items-center justify-between">
            <div>
              <p class="">Today shift real time view</p>
            </div>
            <div class="">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Close'" @buttonAction="closeDialog()"/>
            </div>  
          </div>
          <div class="p-4 bg-gray1"> 
            <div class="cust_card p-2 bg-white font-semibold text-text1 mb-4" >
              <div class="flex gap-2 items-center mb-2">
                <span class="text-success"><i style="font-size:24px" class="fa">&#xf111;</i></span>
                <span>{{viewDetail.workingShiftCount}}</span>
                <span>Working</span>
              </div>
              <div v-if="viewDetail.workingShiftList" class="mt-2">
                <div v-for="(user, i) in viewDetail.workingShiftList" :key="i" class="mb-2 px-2 hover:bg-gray1 cursor-pointer" @click="goToSpeificShiftDetail(user)">
                <div class="">
                  <p>{{user.firstName}}</p>
                  <p class="heading-5 text-gray4 font-normal">Clocked in {{user.shiftStartTime | timeView}}</p>
                </div>
              </div>
              </div>
            </div>
            <div class="cust_card p-2 bg-white font-semibold text-text1 mb-4" >
              <div class="flex gap-2 items-center">
                <span class="text-warning"><i style="font-size:24px" class="fa">&#xf111;</i></span>
                <span>{{viewDetail.InBreakCount}}</span>
                <span>In Break</span>
              </div>
              <div v-if="viewDetail.inBreakShiftList" class="mt-2">
                <div v-for="(user, i) in viewDetail.inBreakShiftList" :key="i" class="mb-2 px-2 hover:bg-gray1 cursor-pointer" @click="goToSpeificShiftDetail(user)">
                <div class="">
                  <p>{{user.firstName}}</p>
                  <p class="heading-5 text-gray4 font-normal">Clocked in {{user.shiftStartTime | timeView}}</p>
                  <p class="heading-5 text-gray4 font-normal">{{user.shiftStartTime | leaveTimeFilterForActivity}} : {{user.breakNote}}</p>
                </div>
              </div>
              </div>
            </div>
            <div class="cust_card p-2 bg-white font-semibold text-text1 mb-4" >
              <div class="flex gap-2 items-center">
                <span class="text-gray3"><i style="font-size:24px" class="fa">&#xf111;</i></span>
                <span>{{viewDetail.completedShiftCount}}</span>
                <span>Completed Shift</span>
              </div>

              <div v-if="viewDetail.completedShiftList" class="mt-2">
                <div v-for="(user, i) in viewDetail.completedShiftList" :key="i" class="mb-2 px-2 hover:bg-gray1 cursor-pointer" @click="goToSpeificShiftDetail(user)">
                <div class="">
                  <p>{{user.firstName}}</p>
                  <p class="heading-5 text-gray4 font-normal">Clocked in at {{user.shiftStartTime | leaveTimeFilterForActivity}}, Clocked out {{user.shiftEndTime | timeView}}</p>
                  <p class="heading-5 text-gray4 font-normal">Shift total {{user.totalTimeInMin | ConvertMinutesInHoursAndMinutes}}</p>
                </div>
              </div>
              </div>
            </div>
            <div class="cust_card p-2 bg-white font-semibold text-text1 mb-4" >
              <div class="flex gap-2 items-center">
                <span class="text-error"><i style="font-size:24px" class="fa">&#xf111;</i></span>
                <span>{{viewDetail.notClockedInCount}}</span>
                <span>Not Clocked in</span>
              </div>
              <div v-if="viewDetail.notClockedInList" class="mt-2">
                <div v-for="(user, i) in viewDetail.notClockedInList" :key="i" class="mb-2 px-2 hover:bg-gray1 cursor-pointer" @click="goToSpeificShiftDetail(user)">
                <div class="">
                  <p>{{user.firstName}}</p>
                  <p class="heading-5 text-gray4 font-normal">Clocked out {{user.shiftEndTime | timeView}}</p>
                  <p class="heading-5 text-gray4 font-normal">Shift total {{user.totalTimeInMin | ConvertMinutesInHoursAndMinutes}}</p>
                </div>
              </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
</div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import MyJobApp from "@/api/MyJobApp.js";
export default {
  components: {
    Button,
  },
  data () {
    return {
      viewDetail: null
    }
  },
  props: [],
  watch: {},
  beforeMount () {
  },
  mounted () {
    this.getViewDetail()
  },
  methods: {
    goToSpeificShiftDetail (data) {
    let url = ''
      url = this.$router.resolve({name: 'shiftDetail', params: {shiftId: data.timeSheetId}})
      window.open(url.href, '_blank')
    },
    getViewDetail () {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
    MyJobApp.ShiftRealTimeView(
      (response) => {
        this.viewDetail = response.Data
      console.log("response eeeeeeeeeee", response);
        this.$store.dispatch("SetLoader", { status: false, message: "" });
      },
      (err) => {
        this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
        this.$store.dispatch("SetLoader", { status: false, message: "" });
      }
    );
    },
    closeDialog () {
        this.$emit('realTimeViewHandle')
    }
  }
}
</script>
<style scoped>
</style>
