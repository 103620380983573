<template>
  <div class="mt-12">
    <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl absolute -top-11 w-full flex items-center justify-between">
          <div>
            Shift list ({{totalCount}})
          </div>
          <div class="">
            <div class="relative">
              <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl" @click.stop="showMenuList = true">
                <i class="fas fa-ellipsis-v"></i>
              </div>
              <div v-if="showMenuList">
                <div v-click-outside="closeList">
                  <div class=" bg-white absolute z-10 right-3 shadow-2xl rounded top-10 w-40">
                      <div class="p-2 text-text2 font-medium text-center">
                        <div class="p-2 cursor-pointer hover:bg-blue-50" @click.stop="addNewShift()">
                          <span class="py-1">Add Shift</span>
                        </div>
                        <div class="p-2 cursor-pointer hover:bg-blue-50" @click.stop="sendEmailReqCall">
                          <span class="py-1">Email Reports</span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8">
        <div class="flex flex-wrap mb-2 items-center">
          <button
            @click="buttonHandler('lastMonth')"
            :class="`${isSelected === 'lastMonth' ? 'bg-info border-info' : 'bg-white border-gray2'}`"
            class="py-1.5 px-3 text-sm border hover:text-primary text-text1 rounded-xl filter_list_btn bottom_space mr-2">
            Last Month
          </button>
          <button
            @click="buttonHandler('lastWeek')"
            :class="`${isSelected === 'lastWeek' ? 'bg-info border-info' : 'bg-white border-gray2'}`"
            class="py-1.5 px-3 text-sm border hover:text-primary text-text1 rounded-xl filter_list_btn bottom_space mr-2">
            Last Week
          </button>
          <button
            @click="buttonHandler('custom')"
            :class="`${isSelected === 'custom' ? 'bg-info border-info' : 'bg-white border-gray2'}`"
            class="py-1.5 px-3 text-sm border hover:text-primary text-text1 rounded-xl filter_list_btn bottom_space">
            Custom
          </button>
          <div class="card bg-white p-2 rounded-xl m-1 ml-2">
            <div class="flex justify-between align-center pa-2" :class="isBarDateSelection ? 'text-primary' : 'text-text2'">
              <span @click="changeDate('pre');isBarDateSelection = true;isSelected = ''"><i class="fas fa-chevron-left  heading-5 cursor-pointer mx-3" ></i></span>
              <p class="date_time_picker__header  cursor-pointer" @click="openSelDatePicker = true;isBarDateSelection = true;isSelected = ''">{{filterObject.startDate | shiftBarDateFilter}}</p>
              <span v-if="!isDiableNext" @click="changeDate('next');isBarDateSelection = true;isSelected = ''" :class="isDiableNext ? 'cursor-not-allowed text-text2' : ' cursor-pointer'" ><i class="fas fa-chevron-right heading-5   mx-3" ></i></span>
              <span v-else :class="isDiableNext ? 'cursor-not-allowed text-text2' : ' cursor-pointer'" ><i class="fas fa-chevron-right heading-5   mx-3" ></i></span>
            </div>
            <div v-if="openSelDatePicker" class="popup_overlay">
              <div style="min-height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                <DateTimePickerCustom
                    v-model="filterObject.startDate"
                    v-if="openSelDatePicker"
                    :showDate="false"
                    :title="'Select Date'"
                    :showOnlyDate="true"
                    :showStartDate="true"
                    :roundedCard="true"
                    :maxDate="getEndDate"
                    :fromProject="false"
                    @closeDateTimepickerDialog="changeDateforSel">
                </DateTimePickerCustom>
              </div>
            </div>
          </div>
        </div>
        <div class="flex overflow-auto mb-2 items-center mt-4">
          <div class="pr-2 text-gray4 heading-5">Employee:</div>
          <div class="" v-for="(status, index) in empStatusList" :key="index" :class="`${index === 0 ? 'left-0 sticky bg-white' : ''}`">
            <StatusBtn class=" whitespace-nowrap my-1" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="status.fullName" :lastInlist="empStatusList.length === index + 1"/>     
          </div>
        </div>
        <div v-if="isDiableNext" class="flex overflow-auto mb-2 items-center mt-4">
          <div @click="showShiftRealTimeView = true" class="pr-2 text-primary cursor-pointer heading-5">Please Click here to View realtime view</div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;">
          <div class="lg:grid grid-cols-12">
            <div v-if="shiftListing.length > 0" class="col-span-5">
              <div v-for="(data, index) in shiftListing" :key="index" :class="data.shitTotal !== 0 ? 'cursor-pointer' : ''"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 " @click="openSpecificShiftList(data)">
                <div class="flex items-center justify-between">
                  <p class="text-gray4 heading-5" v-if="activeUer !== data.userDetailId">{{data.firstName}} {{data.lastName}}</p>
                  <p class="text-gray4 heading-5" v-else>Me</p>
                  <div class="text-primary heading-5 pr-2 items-center flex" v-if="data.shitTotal !== 0">{{data.shitTotal | ConvertMinutesInHoursAndMinutes}}
                    <i v-if="data.shitTotal !== 0" class="fas fa-chevron-right text-primary heading-4 pl-2"></i>
                  </div>
                  <p class="text-gray4 heading-5 pr-2" v-if="data.shitTotal === 0">No Shift logged</p>
                </div>
              </div>
            </div>
            <div class="col-span-7" v-if="isOpenDetail && !mobileView">
              <div class="card m-2 border-b border-gray1  bg-primary p-2 relative items-center ">
                <p class="text-white heading-4 text-center">{{selectedObj.firstName}} {{selectedObj.lastName}} ({{ getDynamicTimeTitle() }})</p>
                <div class=" text-primary heading-5 bg-white px-1.5 py-1 rounded-full flex justify-items-center justify-center absolute right-2 top-2.5 cursor-pointer" @click.stop="isOpenDetail = false">
                  <i class="fas fa-times"></i>
                </div>
                <div class=" text-primary heading-5 bg-white px-1.5 py-1 rounded-full flex justify-items-center justify-center absolute right-10 top-2.5 cursor-pointer" @click.stop="sendEmailReqCall('detail')">
                  <i class="fa-solid fa-file-arrow-down"></i>
                </div>
              </div>
              <div class="card m-2 border-b border-gray1  bg-white p-2 items-center">
                <div class="flex items-center justify-between pb-2">
                  <p class="text-gray4 heading-4">Total Shift Hours</p>
                  <p class="text-primary heading-4 pr-2 items-center flex">{{totalShiftCount | ConvertMinutesInHoursAndMinutes}}</p>
                </div>
                <div class="flex items-center justify-between pb-2">
                  <p class="text-gray4 heading-4">Total Break Hours</p>
                  <p class="text-primary heading-4 pr-2 items-center flex">{{totalBreakCount | ConvertMinutesInHoursAndMinutes}}</p>
                </div>
                <div class="divider mb-2"></div>
                <div class="flex items-center justify-between pb-2">
                  <p class="text-gray4 heading-4">Total Working Hours</p>
                  <p class="text-primary heading-4 pr-2 items-center flex">{{totalShiftCount + totalBreakCount | ConvertMinutesInHoursAndMinutes}}</p>
                </div>
              </div>
              <div class="card m-2 border-b border-gray1 bg-white p-2 items-center border overflow-auto" v-if="specidifTimeSheet.length > 0">
                <div class="flex font-bold text-text2 border-b heading-6 p-2 px-3">
                  <div class="table_col centerLine">
                    <span class="overFlowParaA">Start</span>
                    <span class="overFlowParaA">End</span>
                  </div>
                  <div class="table_col centerLine rightText">
                    <span class="overFlowParaA">Total</span>
                  </div>
                  <div class="table_col centerLine rightText">
                    <span class="overFlowParaA">Break</span>
                  </div>
                  <div class="table_col centerLine rightText">
                    <span class="overFlowParaA">Net</span>
                  </div>
                </div>
                <!-- cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 -->
                <div v-for="(data, index) in specidifTimeSheet" :key="index"  class="card flex bg-white px-3 py-3 hover:bg-gray-100 group cursor-pointer" @click="goToSpeificShiftDetail(data)">
                  <div class="table_col">
                    <div>
                      <span class="heading-6 text-text1" v-if="data.shiftStartTime !== ''">{{ data.shiftStartTime | formatDateTime }}</span>
                      <span class="heading-6 text-text1 pl-4" v-else>--</span>
                    </div>
                    <div>
                      <span class="heading-6 text-text1" v-if="data.shiftEndTime !== ''">{{ data.shiftEndTime | formatDateTime }}</span>
                      <span class="heading-6 text-text1 pl-4" v-else>--</span>
                    </div>
                  </div>
                  <div class="table_col rightText">
                    <span class="heading-6 text-text1">{{data.totalTimeInMin | ConvertMinutesInHoursAndMinutes}}</span>
                  </div>
                  <div class="table_col rightText">
                    <span class="heading-6 text-text1">{{data.totalBreak | ConvertMinutesInHoursAndMinutes}}</span>
                  </div>
                  <div class="table_col rightText">
                    <span class="heading-6 text-text1 overFlowParaA">{{ (data.totalTimeInMin + data.totalBreak) | ConvertMinutesInHoursAndMinutes}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="externalDiv" class="col-span-7" v-if="isOpenDetail && mobileView">
              <div class="card m-2 border-b border-gray1  bg-primary p-2 relative items-center ">
                <p class="text-white heading-4 overFlowParaA">{{selectedObj.firstName}} {{selectedObj.lastName}} ({{ getDynamicTimeTitle() }})</p>
                <div class=" text-primary heading-5 bg-white px-2 rounded-xl absolute right-2 top-2.5 cursor-pointer" @click.stop="isOpenDetail = false">
                  <i class="fas fa-times"></i>
                </div>
              </div>
              <div class="card m-2 border-b border-gray1  bg-white p-2 items-center">
                <div class="flex items-center justify-between pb-2">
                  <p class="text-gray4 heading-5">Total Shift Hours</p>
                  <p class="text-primary heading-5 pr-2 items-center flex">{{totalShiftCount | ConvertMinutesInHoursAndMinutes}}</p>
                </div>
                <div class="flex items-center justify-between pb-2">
                  <p class="text-gray4 heading-5">Total Break Hours</p>
                  <p class="text-primary heading-5 pr-2 items-center flex">{{totalBreakCount | ConvertMinutesInHoursAndMinutes}}</p>
                </div>
                <div class="divider mb-2"></div>
                <div class="flex items-center justify-between pb-2">
                  <p class="text-gray4 heading-5">Total Working Hours</p>
                  <p class="text-primary heading-5 pr-2 items-center flex">{{totalShiftCount + totalBreakCount | ConvertMinutesInHoursAndMinutes}}</p>
                </div>
              </div>
              <div class="card m-2 border-b border-gray1 bg-white p-2 items-center border overflow-auto" v-if="specidifTimeSheet.length > 0">
                <div style="overflow-y: auto; white-space: nowrap;" class="">
                  <div v-for="(data, index) in specidifTimeSheet" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="goToSpeificShiftDetail(data)">
                    <div class="heading-5">
                      <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                      <div class="flex items-center">
                        <p class="text-gray3 w-20">Start:</p>
                        <p class="text-text2" v-if="data.shiftStartTime !== ''">{{data.shiftStartTime | formatDateTime}}</p>
                        <span class="heading-6 text-text1 pl-4" v-else>--</span>
                      </div>
                      <div class="flex items-center">
                        <p class="text-gray3 w-20">End:</p>
                        <p class="text-text2" v-if="data.shiftEndTime !== ''">{{data.shiftEndTime | formatDateTime}}</p>
                        <span class="heading-6 text-text1 pl-4" v-else>--</span>
                      </div>
                      <div class="flex items-center">
                        <p class="text-gray3 w-20">Total:</p>
                        <p class="text-text2">{{data.totalTimeInMin | ConvertMinutesInHoursAndMinutes}}</p>
                      </div>
                      <div class="flex items-center">
                        <p class="text-gray3 w-20">Break:</p>
                        <p class="text-text2">{{data.totalBreak | ConvertMinutesInHoursAndMinutes}}</p>
                      </div>
                      <div class="flex items-center">
                        <p class="text-gray3 w-20">Net:</p>
                        <p class="text-text2">{{ (data.totalTimeInMin + data.totalBreak) | ConvertMinutesInHoursAndMinutes}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="flex font-bold text-text2 border-b heading-6 p-2 px-3">
                  <div class="table_col centerLine">
                    <span class="overFlowParaA">Start</span>
                    <span class="overFlowParaA">End</span>
                  </div>
                  <div class="table_col centerLine rightText">
                    <span class="overFlowParaA">Total</span>
                  </div>
                  <div class="table_col centerLine rightText">
                    <span class="overFlowParaA">Break</span>
                  </div>
                  <div class="table_col centerLine rightText">
                    <span class="overFlowParaA">Net</span>
                  </div>
                </div>
                <div v-for="(data, index) in specidifTimeSheet" :key="index"  class="card flex bg-white px-3 py-3 hover:bg-gray-100 group cursor-pointer" @click="goToSpeificShiftDetail(data)">
                  <div class="table_col">
                    <div>
                      <span class="heading-6 text-text1" v-if="data.shiftStartTime !== ''">{{ data.shiftStartTime | formatDateTime }}</span>
                      <span class="heading-6 text-text1 pl-4" v-else>--</span>
                    </div>
                    <div>
                      <span class="heading-6 text-text1" v-if="data.shiftEndTime !== ''">{{ data.shiftEndTime | formatDateTime }}</span>
                      <span class="heading-6 text-text1 pl-4" v-else>--</span>
                    </div>
                  </div>
                  <div class="table_col rightText">
                    <span class="heading-6 text-text1">{{data.totalTimeInMin | ConvertMinutesInHoursAndMinutes}}</span>
                  </div>
                  <div class="table_col rightText">
                    <span class="heading-6 text-text1">{{data.totalBreak | ConvertMinutesInHoursAndMinutes}}</span>
                  </div>
                  <div class="table_col rightText">
                    <span class="heading-6 text-text1 overFlowParaA">{{ (data.totalTimeInMin + data.totalBreak) | ConvertMinutesInHoursAndMinutes}}</span>
                  </div>
                </div> -->
              </div>
            </div>
            <!-- <div v-else>
              <NoRecords :alertMessage="`No records found.`" />
            </div>  -->
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white container" v-if="showCustomDatepicker" >
      <div class="popup_overlay">
        <CustomDateSelector :appliedFilters="btnDatePicker" @closeSelectedDialog="customDateListener" v-if="showCustomDatepicker" :isShowMore="true"/>
      </div>
    </div>
    <div class="popup_overlay relative px-4" v-if="sendEmailReq || sendEmaildetailReq">
      <div style="width: 440px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-4 font-semibold">
          <p class="heading-4 text-text1">Export Shift Data?</p>
        </div>
        <div  class="mb-5 font-bold">
          <p class="heading-6 text-text1">System Will send excel file in email based on applied filter</p>
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'No'" @buttonAction="closeBtn"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Send Request'" @buttonAction="sendReqBtn"/>
        </div>
    </div>
  </div>
  <ShiftReamTimeView v-if="showShiftRealTimeView" @realTimeViewHandle="realTimeViewHandle" />
  </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import DateTimePickerCustom from '@/View/components/dateTimepicker'
import moment from 'moment'
import CustomDateSelector from '@/View/components/customDateSelection'
import {getDayAccordingToLimits} from '@/utils/convertDateAndTime.js'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import Button from '@/View/components/globalButton.vue'
import MyJobApp from "@/api/MyJobApp.js";
import ShiftReamTimeView from "./ShiftReamTimeView.vue";
// import NoRecords from '@/View/components/noRecords.vue'
export default {
components: {
  // NoRecords,
  ShiftReamTimeView,
  Button,
  DateTimePickerCustom,
  CustomDateSelector,
  StatusBtn,
},
props: [],
data () {
  return {
    showShiftRealTimeView: false,
    canExportPermission: FilterPermissions('shift_export_data'),
    canAddShiftPermission: FilterPermissions('shift_add_manual'),
    mobileView: false,
    isOpenDetail: false,
    isBarDateSelection: true,
    openSelDatePicker: false,
    isDiableNext: true,
    isSelected: '',
    totalCount: 0,
    showCustomDatepicker: false,
    TempStartDate: '',
    sendEmailReq: false,
    sendEmaildetailReq: false,
    showMenuList: false,
    TempEndDate: '',
    specidifTimeSheet: [],
    totalBreakCount: 0,
    totalShiftCount: 0,
    resetPaginationValues: false,
    activeUer: 0,
    btnDatePicker: {
      startDate: '',
      endDate: '',
      exportData: false,
      selectedEmpId: '',
      selectedValue: '',
    },
    filterObject: {
      startDate: '',
      endDate: '',
      exportData: false,
      selectedEmpId: '',
    },
    shiftListing: [],
    empStatusList: [],
    selectedObj: {},
    titleObj: [
      {title: 'Name', icon: '', sortName: 'firstName'},
      {title: 'Total Time(Min)', icon: '', sortName: 'totalTimeInMin'},
      {title: 'Start Time', icon: '', sortName: 'shiftStartTime'},
      {title: 'End Time', icon: '', sortName: 'shiftEndTime'},
      {title: 'Total Breaks', icon: '', sortName: 'totalBreak'},
    ] 
  }
},
created() {
    window.addEventListener("resize", this.resizeWindowHandler);
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
computed: {
    getEndDate () {
      var endDate = new Date()
      return endDate.toISOString().slice(0, 10)
    }
  },
mounted () {
  let Today = new Date()
  this.filterObject.startDate = new Date((Today).setHours(0, 0, 0, 0)).toUTCString()
  this.filterObject.endDate = new Date((Today).setHours(24, 0, 0, 0)).toUTCString()
  this.btnDatePicker.startDate = new Date((Today).setHours(0, 0, 0, 0)).toUTCString()
  this.btnDatePicker.endDate = new Date((Today).setHours(24, 0, 0, 0)).toUTCString()
  this.getEmployeeList()
  this.getShiftListing(false)
},
watch: {
},
methods: {
  realTimeViewHandle () {
    this.showShiftRealTimeView = false
  },
  sendEmailReqCall (from) {
    if (this.canExportPermission) {
      if (from === 'detail') {
        this.sendEmaildetailReq = true
      } else {
        this.sendEmailReq = true
      }
    } else {
      this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to Export Email Reports`})
    }
  },
  addNewShift () {
    if (this.canAddShiftPermission) {
      this.$router.push({name: 'AddShift'})
    } else {
      this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to Add Shift`})
    }
  },
  closeBtn () {
    this.sendEmailReq = false
    this.sendEmaildetailReq = false
  },
  sendReqBtn () {
    if (this.sendEmailReq) {
      this.getShiftListing(true)
    } 
    if (this.sendEmaildetailReq) {
      this.openSpecificShiftList(null)
    }
    this.sendEmailReq = false
    this.filterObject.exportData = true
    
  },
  buttonHandler (value) {
    this.isBarDateSelection = false
    this.isSelected = value
    if (value !== 'custom') {
      getDayAccordingToLimits(value).then(val => {
        this.btnDatePicker.startDate = val.startDate
        this.btnDatePicker.endDate = val.endDate
        this.getShiftListing(false)
        this.btnDatePicker.selectedValue = ''
      })
    } else {
      this.showCustomDatepicker = true
    }
  },
  customDateListener (data) {
    this.showCustomDatepicker = false
    if (data !== null) {
      this.btnDatePicker.startDate = data.startDate
      this.btnDatePicker.endDate = data.endDate
      this.btnDatePicker.selectedValue = data.selectedValue
    }
    this.getShiftListing(false)
  },
  changeDateforSel () {
    this.openSelDatePicker = false
    let temp = new Date(this.filterObject.startDate)
    this.filterObject.startDate = new Date(temp.setHours(0, 0, 0, 0)).toUTCString();
    this.filterObject.endDate = new Date(temp.setHours(24, 0, 0, 0)).toUTCString();
    if (moment(new Date()).format('YYYY-MM-DD') === moment(new Date(this.filterObject.startDate)).format('YYYY-MM-DD')) {
      this.isDiableNext = true
    } else {
      this.isDiableNext = false
    }
    this.isSelected = ''
    this.btnDatePicker.selectedValue = ''
    this.getShiftListing(false)
  },
  changeDate (value) {
    const fObj = this.filterObject
    let fDate = fObj.startDate === '' ? new Date() : new Date(fObj.startDate);
    let newDateT = new Date();
    if (value === 'pre') {
      newDateT = new Date(fDate.setDate(fDate.getDate() - 1));
      fObj.startDate = new Date(newDateT.setHours(0, 0, 0, 0)).toUTCString()
      fObj.endDate = new Date(newDateT.setHours(24, 0, 0, 0)).toUTCString()
    } else {
      if (!this.isDiableNext) {
        newDateT = new Date(fDate.setDate(fDate.getDate() + 1));
        fObj.startDate = new Date(newDateT.setHours(0, 0, 0, 0)).toUTCString();
        fObj.endDate = new Date(newDateT.setHours(24, 0, 0, 0)).toUTCString();
      }
    }
    if (moment(new Date()).format('YYYY-MM-DD') === moment(new Date(fObj.startDate)).format('YYYY-MM-DD')) {
      this.isDiableNext = true
    } else {
      this.isDiableNext = false
    }
    this.getShiftListing(false)
  },
  getDynamicTimeTitle () {
    const requestDt = new Date(this.TempStartDate).setHours(0, 0, 0);
    const todayDate = new Date().setHours(0, 0, 0);
    if (
      moment(todayDate).format('YYYY/MM/DD') ===  moment(requestDt).format('YYYY/MM/DD')
    ) {
      return `Today`;
    } else if (todayDate > requestDt) {
      const diffTime = Math.abs(todayDate - requestDt);

      const diffDays = Math.ceil(
        parseFloat((diffTime / (1000 * 3600 * 24)).toFixed(2)),
      );

      if (diffDays === 1) {
        return `Yesterday`;
      } else {
        return moment(this.TempStartDate).format('DD MMM, YY') +' to '+ moment(this.TempEndDate).format('DD MMM, YY')
      }
    }
  },
  closeList () {
    this.showMenuList = false
  },
  getShiftListing(value) {
    this.isOpenDetail = false
    this.TempStartDate = ''
    this.TempEndDate = ''
    if (!this.isBarDateSelection) {
      this.TempStartDate = this.btnDatePicker.startDate
      this.TempEndDate = this.btnDatePicker.endDate
    } else {
      this.TempStartDate = this.filterObject.startDate
      this.TempEndDate = this.filterObject.endDate
    }
    this.filterObject.selectedEmpId = []
      this.empStatusList.map((data) => {
      if (data.selected) {
        this.filterObject.selectedEmpId.push(data.userDetailId)
      }
    })
    this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
    MyJobApp.getSiftList(
      this.filterObject.selectedEmpId,
      this.TempStartDate,
      this.TempEndDate,
      value,
      (response) => {
        if (value) {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        } else {
          this.resetPaginationValues = false
          this.shiftListing = []
          let shiftListing = []
          shiftListing = response.Data !== null ? response.Data  : []
          for (let index = 0; index < shiftListing.length; index++) {
            shiftListing[index].isOpen = false
          }
          this.shiftListing = shiftListing
          this.totalCount = this.shiftListing.length
        }
        this.$store.dispatch("SetLoader", { status: false, message: "" });
      },
      (err) => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
        this.$store.dispatch("SetLoader", { status: false, message: "" });
      }
    );
  },
  toggleActiveAndInactiveB (index) {
      let filter = []
      if (this.empStatusList[index].fullName.toLowerCase() === 'all') {
        this.empStatusList[0].selected = true
        this.filterObject.selectedEmpId = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      } else {
        this.empStatusList[index].selected = !this.empStatusList[index].selected
        this.filterObject.selectedEmpId = []
        this.empStatusList.forEach((a, id) => {
          if (this.empStatusList[0].selected) {
            if (id !== index && id !== 0) {
              a.selected = false
            } else {
              if (id !== 0) {
                a.selected = true
                this.filterObject.selectedEmpId.push(a.userDetailId)
              }
            }
          } else {
            if (a.selected) {
              filter.push(a)
              this.filterObject.selectedEmpId.push(a.userDetailId)
            }
          }
        })
        this.empStatusList[0].selected = false
      }
      if (filter.length === this.empStatusList.length - 1) {
        this.empStatusList[0].selected = true
        this.filterObject.selectedEmpId = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      let arry = this.empStatusList.filter(a => {
        return a.selected === false
      })
      if (arry.length === this.empStatusList.length) {
        this.empStatusList[0].selected = true
        this.filterObject.selectedEmpId = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      this.resetPaginationValues = true
      this.getShiftListing(false)
    },
  getEmployeeList () {
    MyJobApp.getEmployeeList(
      'active',
      response => {
        let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
        for (let index = 0; index < temp.length; index++) {
          temp[index].selected = true
          temp[index].fullName = temp[index].firstName + temp[index].lastName.substr(0, 1)
        }
        const newFirstElement = {
          userDetailId: 0,
          fullName: "All",
          selected: true
        }
        this.empStatusList = [newFirstElement].concat(temp)
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      },
      () => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
    )
  },
  openSpecificShiftList (data) {
    if (data === null) {
      MyJobApp.getSpecificSiftList(
        0,
        20,
        this.selectedObj.userDetailId,
        'totalTimeInMin',
        'DESC',
        '',
        this.TempStartDate,
        this.TempEndDate,
        this.sendEmaildetailReq,
        (response) => {
          this.sendEmaildetailReq = false
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.sendEmaildetailReq = false
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    } else if (data.shitTotal !== 0) {
      this.totalBreakCount = 0
      this.totalShiftCount = 0
      this.specidifTimeSheet = []
      this.selectedObj = data
      MyJobApp.getSpecificSiftList(
        0,
        20,
        data.userDetailId,
        'totalTimeInMin',
        'DESC',
        '',
        this.TempStartDate,
        this.TempEndDate,
        false,
        (response) => {
          this.specidifTimeSheet = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < this.specidifTimeSheet.length; index++) {
            this.totalBreakCount += this.specidifTimeSheet[index].totalBreak
            this.totalShiftCount += this.specidifTimeSheet[index].totalTimeInMin
          }
          this.isOpenDetail = true
          if (this.mobileView) {
            setTimeout(() => {
              document.getElementById('externalDiv').scrollIntoView({behavior: "smooth",block: 'nearest'})
            }, 170);
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    }
    // this.$router.push({name: 'shiftDetail', params: {userId: Index}})
  },
  resizeWindowHandler() {
    if (window.innerWidth < 1000) {
      this.mobileView = true
    } else {
      this.mobileView = false
    }
  },
  goToSpeificShiftDetail (data) {
    let url = ''
    url = this.$router.resolve({name: 'shiftDetail', params: {shiftId: data.timeSheetId}})
    window.open(url.href, '_blank')
  },
}
}
</script>
<style scoped>
.table_col {
  min-width: 130px !important;
  width: 200px !important;
  max-width: 200px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
